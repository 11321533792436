<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <FullCalendar :options="calendarOptions" />
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-default">
            <div class="modal-dialog">
                <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Agenda</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-6 form-group">
                <label class="control-label">Range waktu</label>
                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                <p>{{form.start}}</p>
            </div>
            <div class="row" style="padding: 7.5px;">
                <div class="col-md-6 form-group">
                    <label class="control-label">Mulai</label>
                    <p>{{form.time_start}}</p>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Selesai</label>
                    <p>{{form.time_end}}</p>
                </div>
            </div>
            <div class="col-md-12 form-group">
                <label class="control-label">Agenda</label>
                <p>{{form.agenda}}</p>
            </div>
            <div class="col-md-12 form-group">
                <label class="control-label">Kelas</label>
                <p>{{form.kelas}}</p>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </div>
</template>

<script type="text/javascript">
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid';
    import listPlugin from '@fullcalendar/list';
    import interactionPlugin from '@fullcalendar/interaction'
    import { authFetch } from '@/libs/hxcore';
    import $ from 'jquery';
    import timepicker from '@/components/Timepicker';
    import Daterangepicker from 'daterangepicker';
    import "daterangepicker/daterangepicker.css";
    import vSelect from 'vue-select';
    import "vue-select/dist/vue-select.css";
    import moment from 'moment';

    export default {
        name: 'Kalender',
        components: {
            FullCalendar,
            vSelect,
            timepicker,
        },
        data() {
            return {
                errors: [],
                form: {
                    date:'',
                    start: '',
                    end: '',
                    agenda: '',
                    kategory: '',
                    description: '',
                    time_start:'',
                    time_end:'',
                    priority:'',
                    title:'',
                },
                calendarOptions: {
                    plugins: [listPlugin],
                    initialView: 'listMonth',
                    dateClick: this.handleDateClick,
                    eventClick: this.eventClick,
                    events: [],
                    displayEventTime: false,
                    headerToolbar: {
                        left: 'prev,next today',
                        right: ''
                    },
                },
                
            };
        },
        created: function () {
            if (this.$route.params.k_id) {
                console.log(this.$route.params.k_id);
                let id = this.$route.params.k_id;
                this.openModal(id);
            } 
        },
        mounted() {
            var self = this;
            new Daterangepicker(this.$refs.daterange, {
            }, function(d1, d2) {
                self.form.start = d1.format('DD/MM/YYYY')
                self.form.end = d2.format('DD/MM/YYYY')
            });

            authFetch('/tentor/kalender')
                .then(res => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.calendarOptions.events = js.data;
                })
        },
        methods: {
            eventClick: function(arg) {
                console.log(arg);
                let route = '';
                    route = '/tentor/kalender/'+arg.event.id;
                var self = this;
                authFetch(route)
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        this.form.start = moment(js.start).format('DD/MM/YYYY');
                        this.form.end = moment(js.end).format('DD/MM/YYYY');
                        this.form.agenda = js.title;
                        this.form.time_start = js.time_start ? js.time_start.substring(0,5) : ''
                        this.form.time_end = js.time_end ? js.time_end.substring(0,5) : '-'
                        this.form.id = js.id;
                        this.form.kelas = js.kelas;
                        $("#modal-default").modal();
                    });
            },
            handleDateClick: function(arg) {
                var self = this;
                this.$refs.form.reset();
                this.form.id = '';
                this.form.start = '';
                this.form.end = '';
                this.form.agenda = '';
                this.form.time_start = '';
                this.form.time_end = '';

                $('input[name="row_id"]').val('')
                
                var date = ('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+('0' + arg.date.getDate()).slice(-2)+'/'+arg.date.getFullYear();
                $('#daterange').daterangepicker({ startDate: date, endDate: date });
                this.form.start = ('0' + arg.date.getDate()).slice(-2)+'/'+('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+arg.date.getFullYear()
                this.form.end = ('0' + arg.date.getDate()).slice(-2)+'/'+('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+arg.date.getFullYear()
                $("#modal-default").modal()

                $('#daterange').on('apply.daterangepicker', function(ev, picker) {
                    self.form.start = picker.startDate.format('DD/MM/YYYY')
                    self.form.end = picker.endDate.format('DD/MM/YYYY')
                });
            },
            openModal: function(arg) {
                console.log(arg);
                let route = '';
                if (this.$route.params.k_id) {
                    route = '/tentor/kalender/'+arg;
                }
                var self = this;
                authFetch(route)
                    .then(res => {
                        if (res.status === 201) {
                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        this.form.start = moment(js.start).format('DD/MM/YYYY');
                        this.form.end = moment(js.end).format('DD/MM/YYYY');
                        this.form.agenda = js.title;
                        this.form.time_start = js.time_start ? js.time_start.substring(0,5) : ''
                        this.form.time_end = js.time_end ? js.time_end.substring(0,5) : '-'
                        this.form.id = js.id;
                        $("#modal-default").modal();
                    });
            },
        }
    }
</script>